// Override default variables before the import
$body-bg: #000;
$white: #fff;
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

$font-family-serif: 'Merriweather', serif; // Your desired font family
$font-family-sans-serif: 'Urbanist', sans-serif; // Your desired font family
$font-family-base: $font-family-sans-serif; // Set the base font family to your custom font

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

// Override heading styles to use the serif font family
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-serif;
  color: $white;
  line-height: 1.2em;
}
h1,
h2,
h3,
h4,
h5,
h6,
caption,
figcaption {
  text-wrap: balance;
}

p {
  color: #c2c2c2;
  text-wrap: balance;
}

.ReactApp {
  background-color: #000;
}

.rounded {
  border-radius: 50% !important;
}
.img_border {
  border: 0.5rem solid #fff;
  box-shadow: rgba(0, 0, 0, 0.75) 0px 5px 15px;
}
.project_image img {
  max-width: 600px;
  display: block;
  margin: auto;
  border: 0.5rem solid #fff;
  box-shadow: rgba(0, 0, 0, 0.75) 0px 5px 15px;
  &:hover {
    transform: scale(1.1);
    transition: transform 0.5s;
  }
}
.project_text {
  padding-top: 1rem;
}
.desktop_only {
  display: block;
}
.mobile_only {
  display: none;
}
@media screen and (max-width: 768px) {
  .desktop_only {
    display: none;
  }
  .mobile_only {
    display: block;
  }
  .project_text {
    h2 {
      font-size: 1.8rem;
    }
  }
}

.tech span {
  padding: 5px 10px;
  margin: 6px;
  border-radius: 5px;
  color: #fff; /* White text color */
  font-weight: bold;
  display: inline-block;
}

.tech span:nth-child(1) {
  background-color: #3498db; /* Blue */
}

.tech span:nth-child(2) {
  background-color: #e74c3c; /* Red */
}

.tech span:nth-child(3) {
  background-color: #2ecc71; /* Green */
}

.tech span:nth-child(4) {
  background-color: #f1c40f; /* Yellow */
}

.tech span:nth-child(5) {
  background-color: #9b59b6; /* Purple */
}
.tech span:nth-child(6) {
  background-color: #3498db; /* Blue */
}

.tech span:nth-child(7) {
  background-color: #e74c3c; /* Red */
}

.tech span:nth-child(8) {
  background-color: #2ecc71; /* Green */
}

.tech span:nth-child(9) {
  background-color: #f1c40f; /* Yellow */
}

.tech span:nth-child(10) {
  background-color: #9b59b6; /* Purple */
}
