.slide_introduction {
  h3 {
    line-height: 2.8rem;
  }
}

@media screen and (max-width: 768px) {
  .slide_introduction {
    h3 {
      line-height: 1.8rem;
      font-size: 1.2rem;
    }
  }
  .Intro p {
    font-size: 1rem;
  }
}
