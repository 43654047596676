.slide3 {
  h2 {
    font-size: 3rem;
    color: #c2c2c2;
    strong {
      color: #fff;
    }
  }
  .arrow-container {
    position: absolute;
    bottom: 20px; /* Adjust as necessary */
    left: 50%;
    transform: translateX(-50%);
    /* More styling as needed */
    font-size: 3rem;
    color: #969494;
  }
  @keyframes flash {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  .flash-animation {
    animation: flash 0.5s 3;
    animation-delay: 4s;
    opacity: 1;
  }
}
