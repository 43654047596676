.Intro {
  color: #fff;
  h1 {
    font-size: 4.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  h2 {
    font-size: 2.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.8rem;

    &:first-of-type {
      margin-top: 2rem;
    }
  }
  .arrow-container {
    position: absolute;
    bottom: 20px; /* Adjust as necessary */
    left: 50%;
    transform: translateX(-50%);
    /* More styling as needed */
    font-size: 3rem;
    color: #969494;
  }
  @keyframes flash {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  .flash-animation {
    animation: flash 0.5s 3;
    animation-delay: 4s;
    opacity: 1;
  }
}
@media screen and (max-width: 768px) {
  .Intro {
    h1 {
      font-size: 2.5rem;
      text-align: center;
    }
    h2 {
      font-size: 1.5rem;
      text-align: center;
    }
    p {
      font-size: 1.3rem;
      text-align: center;
    }
  }
  img {
    max-width: 70vw !important;
    display: block;
    margin: auto;
  }
}
