.rps-slide-background {
  background-size: cover;
  opacity: 0.4;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.rps-slide:nth-of-type(2) .rps-slide-background {
  background-position: 0% 0%;
}
.rps-slide:nth-of-type(4) .rps-slide-background {
  background-image: linear-gradient(to left bottom, #090537, #0d145c, #181f83, #262bad, #3636d8);
}
