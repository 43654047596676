.Outro {
  color: #fff;
  position: relative;
  a {
    color: #fff;
    font-weight: bold;
  }
  .socials {
    padding-top: 1rem;
  }
  .social-icon {
    font-size: 3rem;
    padding-right: 2rem;
    color: #c4c4c4;
    margin-top: 3rem;
  }
  footer {
    position: absolute;
    bottom: 0;
    left: 0px;
    right: 0px;
    text-align: center;
    padding: 1rem;
  }
  input,
  textarea {
    background-color: #1f1f1f;
    color: #fff;
    border: 1px solid #fff;
    padding: 0.75rem 0.5rem;
    margin: 0.5rem;
    width: 100%;
    margin-left: 0px;
  }
  input::placeholder,
  textarea::placeholder {
    color: #9c9c9c; /* Your desired placeholder color */
    opacity: 1; /* Bootstrap might set this to a lower value, ensuring full color visibility */
  }

  /* Or, if you want to target a specific input by ID */
  #emailInput::placeholder {
    color: #9c9c9c; /* Your desired placeholder color */
  }

  /* For a specific form or input type */
  .form-control::placeholder {
    color: #9c9c9c;
  }
  .form-control:focus {
    color: #fff;
    background-color: #1f1f1f;
    border: 1px solid #fff;
  }
}
